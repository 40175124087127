//  @flow

// filemanager
export const LOAD_CONTENT = "LOAD_CONTENT";
export const DELETE_CONTENT_ITEMS = "DELETE_CONTENT_ITEMS";
export const SET_CONTENT_ITEMS = "SET_CONTENT_ITEMS";
export const ADD_CONTENT_ITEM = "ADD_CONTENT_ITEM";
export const DELETE_CONTENT_ITEM = "DELETE_CONTENT_ITEM";
export const MODIFY_CONTENT_ITEM = "MODIFY_CONTENT_ITEM";
export const SET_CONTENT_KEYS = "SET_CONTENT_KEYS";

// shared links
export const SET_DEFAULT_BRANDING = "SET_DEFAULT_BRANDING";

// breadcrumb
export const LOAD_BREADCRUMB = "LOAD_BREADCRUMB";
export const DELETE_BREADCRUMB = "DELETE_BREADCRUMB";

// left menu
export const SET_LEFT_MENU_CONTENT = "SET_LEFT_MENU_CONTENT";
export const SET_PAGE_INFO = "SET_PAGE_INFO";

// items info panel
export const SET_ITEMS_INFO_PANEL_DATA = "SET_ITEMS_INFO_PANEL_DATA";

export const SET_ACTIVE_EXPLORE_CARD = "SET_ACTIVE_EXPLORE_CARD";
export const INCREMENT_EXPLORE_CARD = "INCREMENT_EXPLORE_CARD";
export const DECREMENT_EXPLORE_CARD = "DECREMENT_EXPLORE_CARD";
export const SET_GRID_HEADER_HEIGHT = "SET_GRID_HEADER_HEIGHT";

const hashManager = (() => {
    const _serialize = (obj) => {
        let str = [];
        for (let index in obj) {
            if (obj.hasOwnProperty(index) && index !== "" && typeof obj[index] !== "undefined") {
                str.push(
                    encodeURIComponent(index) +
                        "=" +
                        encodeURIComponent(obj[index])
                );
            }
        }
        return str.join("&") || "";
    };

    const _getData = () => {
        let hash = window.location.hash.replace(/^[^#]*#?(.*)$/, "$1");
        hash = decodeURIComponent(hash);

        return hash.split("&").reduce(function(res, item) {
            const parts = item.split("=");
            res[parts[0]] = parts[1];
            return res;
        }, {});
    };

    const _getURL = (forceMyUrl = false) => {
        let hostname = location.hostname;
    
        // Check if forceMyUrl is true and hostname is either e.pcloud.com or u.pcloud.com
        if (forceMyUrl && (hostname === "e.pcloud.com" || hostname === "u.pcloud.com")) {
            hostname = "my.pcloud.com";
        }
    
        return (
            location.protocol +
            "//" +
            hostname +
            (location.port ? ":" + location.port : "") +
            location.pathname +
            (location.search ? location.search : "")
        );
    };
    

    /**
     * 
     * @param {*} newState 
     *      (String) A serialized params string or a hash string beginning with # to merge into location.hash.
     *      (Object) A params object to merge into location.hash.
     * @param {*} mergeMode 
     *      (Number) Merge behavior defaults to 0 if merge_mode is not specified (unless a hash string beginning with # is specified, in which case merge behavior defaults to 2), and is as-follows:
     * 
     * 0: params in the params argument will override any params in the current state.
     * 1: any params in the current state will override params in the params argument.
     * 2: params argument will completely replace current state.
     */
    const _pushState = (newState, mergeMode, forceNavigationToMy = false) => {
        const url = _getURL(forceNavigationToMy);

        if (newState === undefined || (typeof newState === "string" && /^#/.test(newState) && mergeMode === undefined)) {
            // Params string begins with # and merge_mode not specified, so completely
            // overwrite window.location.hash.
            mergeMode = 2;
        }

        let newUrl = url;
        switch (mergeMode) {
            case 1: 
                if (typeof newState === "object") {
                    newUrl = url + "#" + _serialize({...newState, ..._getData()} || {})
                } else {
                    // TODO for string...
                }
                break;
            case 2:
                newUrl = url + "#" + _serialize(newState || {})
                break;
            default: // 0
                if (typeof newState === "object") {
                    newUrl = url + "#" + _serialize({..._getData(), ...newState} || {})
                } else {
                    // TODO for string...
                }
        }

        window.location.href = newUrl;
    };

    const _removeState = (key) => {
        const data = _getData();

        if (key && data.hasOwnProperty(key)) {
            delete data[key];
        }

        // Set the state, completely overriding any existing state.
        _pushState(data, 2);
    };

    const _getState = (key) => {
        const data = _getData();

        if (key) {
            return data.hasOwnProperty(key) ? data[key] : null;
        }

        return data;
    };

    return {
        getState: _getState,
        pushState: _pushState,
        removeState: _removeState
    };
})();

export default hashManager;

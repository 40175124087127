// @flow
// WARNING: Do not use HFN in this component

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Componentify from "react-componentify";
import styled from "styled-components";
import shallowCompare from "react-addons-shallow-compare";

import Modal, { OvalModalWrap } from "../../components/Modal"; // DONE
import register from "../../api/register"; // DONE
import { errorKeys } from "../../lib/errors"; // DONE
import { __ } from "../../lib/translate"; // DONE
import { validateEmail, getSearch, getHash, setcookie, rcookie, getLocationById, hrefSearch, isValidUrl } from "../../lib/utils"; // DONE

import { SHAREASALE_REFS, LOGIN_THEMES, DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import { boldConverter, linkConverter, linkConverterV2 } from "../../lib/componentifyConverters"; // DONE
import { setGlobalLocationById, getApiServerCall } from "@pcloud/web-utilities/dist/api/utils.api"; // DONE
import apiConfig from "../../api/config"; // DONE
import DataCenterDropdown from "./DataCenterDropdown"; // DONE
import * as FormStyle from "../../components/FormSharedComponents"; // DONE
import * as Style from "../SharedInvitationComponents"; // DONE
import type { LocationType } from "../../types/dataregion"; // DONE

import SignupUI from "./AuthenticationStepsUI/SignupUI";
import { notify } from "./MultiplatformComponents/Notification";

const Registration = (props) => {
  const {
    os,
    email = "",
    language = "en",
    subTitle = "",
    showTitle = true,
    title = "",
    beforeRegisterMessage = "",
    beforeRegister = null,
    onRegister = () => { },
    onSuccess = () => { },
    onError = () => { },
    locations = [],
    promoCode = "",
    passpcloud = false,
    userRegionId,
    isLogged = false,
    isWebview = false,
    onEmailChangeClick = () => { },
    formFields,
    themeMode = "light",
    usegrecaptcha,
    device,
    deviceid,
  } = props;
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [error, setError] = useState("");
  const [shouldRenderBeforeRegister, setShouldRenderBeforeRegister] = useState(false);

  const onPasswordChange = e => {
    setPassword(e.target.value)
  }

  const onTermsChange = () => {
    setTerms(!terms);
  }

  const onCreateAccountClick = e => {
    e.preventDefault();

    if (isRegistering || isRegistered || !email || !password) {
      return;
    }
    setIsRegistering(true);
    const isSameCurrentLocation = parseInt(apiConfig.locationid) === parseInt(userRegionId);

    if (beforeRegisterMessage && !isSameCurrentLocation) {
      createAccountStatus(() => setShouldRenderBeforeRegister(true));
    } else {
      createAccountStatus(() => {
        if (typeof grecaptcha !== "undefined" && usegrecaptcha) {
          console.log("grecaptcha init");
          grecaptcha.ready(function () {
            grecaptcha.execute('6Ld3iconAAAAAMeIhfk_3jTdIPSNX_OcSY6QlvZR', { action: 'submit' }).then((gresponsetoken) => {
              console.log("grecaptcha ready");
              createAccount(gresponsetoken)
            });
          });
        } else {
          createAccount()
        }
      });
    }
  }

  const onYesClick = () => {
    setShouldRenderBeforeRegister(false);
    createAccountStatus(createAccount);
  }

  const onCloseBeforeRegisterClick = () => {
    setShouldRenderBeforeRegister(false)
  }

  const createAccountStatus = cb => {
    let error = "";

    if (isRegistering) {
      error = __("registration_in_progress", "Registration in progress");
    }

    if (!terms) {
      error = __("must_agree_terms", "You must agree with Terms of Service.");
    }
    if (password.length < 6) {
      error = __("password_too_short", "Password is too short.");
    }
    if (!password) {
      error = __("password_required", "Password is requried.");
    }
    if (!validateEmail(email)) {
      error = __("invalid_email", "Invalid Email");
    }

    if (error) {
      showMessage(error, "error");
      setIsRegistering(false);
    } else {
      cb();
    }
  }

  const createAccount = (gresponsetoken) => {
    const urlref = rcookie("ref") || getHash("ref") || getSearch("ref");
    const apiServer = getLocationById(userRegionId) && getLocationById(userRegionId).api;

    let opts = {
      username: email,
      password: password,
      language: language,
      passpcloud: passpcloud,
      apiServer: apiServer,
      device: device,
      deviceid: deviceid,
      gresponsetoken: gresponsetoken,
      os: os
    };

    if (promoCode) {
      opts.promocode = promoCode;
    }

    setError("");

    register({
      ...opts,
      onDone: userInfo => {
        const recaptchaBadge = document.querySelector(".grecaptcha-badge");

        if (SHAREASALE_REFS.some(ref => ref == urlref)) {
          setcookie("shareasale", userInfo.userid);
        }
        setcookie("email", email);
        setGlobalLocationById(userRegionId);
        getApiServerCall();
        setIsRegistered(true);
        onSuccess({
          action: "register",
          userinfo: userInfo,
          locationid: userRegionId,
          redirecturl: hrefSearch("redirecturl"),
        });

        if (recaptchaBadge) {
          recaptchaBadge.style.visibility = "hidden";
        }
      },
      onError: ret => {
        const errorMessage = errorKeys[ret.result] || ret.error;
        setIsRegistering(false);
        showMessage(__(errorMessage), "error");
        onError(ret);
      },
      onTimeout: () => {
        setIsRegistering(false);
        showMessage(__("error_connection_timed_out"), "error");
      }
    });
  }

  const showMessage = (message, type) => {
    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && type === "error") {
      setError(message)
    } else {
      notify(message, type, { os });
    }
  }

  const renderBeforeRegisterModal = () => (
    <Modal opened={shouldRenderBeforeRegister} onClose={onCloseBeforeRegisterClick}>
      <OvalModalWrap>
        <Style.Title className="modal before-continue">{__("before_you_continue", "Before you continue")}</Style.Title>
        <Style.WarningMessage className="modal">
          <Style.ContainerCol>
            <Style.WarnIcon className="modal" />
            <Style.ComponentifyWrapper className="modal">
              <Componentify text={beforeRegisterMessage} converters={[boldConverter]} />
            </Style.ComponentifyWrapper>
          </Style.ContainerCol>
        </Style.WarningMessage>

        <Style.ContainerCol>
          <Style.ButtonContainerInline className="modal">
            <Style.ButtonBlue onClick={onYesClick}>{__("Yes")}</Style.ButtonBlue>
            <Style.ButtonGrey onClick={onCloseBeforeRegisterClick}>{__("Cancel")}</Style.ButtonGrey>
          </Style.ButtonContainerInline>
        </Style.ContainerCol>
        <Style.CloseButton onClick={onCloseBeforeRegisterClick} />
      </OvalModalWrap>
    </Modal>
  );

  if (isLogged) {
    return <div />;
  }

  return (
    <React.Fragment>
      {renderBeforeRegisterModal()}
      <SignupUI
        os={os}
        error={error}
        terms={terms}
        isWebview={isWebview}
        email={email}
        password={password}
        formFields={formFields}
        locations={locations}
        disabled={isRegistering || isRegistered || !email || !password}
        loading={isRegistering}
        onTermsChange={onTermsChange}
        onPasswordChange={onPasswordChange}
        onSubmit={onCreateAccountClick}
        onEmailChangeClick={onEmailChangeClick}
        themeMode={themeMode}
        language={language}
        {...props}
      />
    </React.Fragment>
  );
}

export default connect(
  ({ user: { userRegionId } = {} }) => {
    return { userRegionId };
  },
  dispatch => bindActionCreators({}, dispatch)
)(Registration);

export const RegistrationWrap = ({ children }) => <div className="registration-wrap">{children}</div>;

const Wrapper = styled.div`
  position: relative;

  .butt {
    border-radius: 7px;
  }
`;

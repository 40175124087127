import { detectIsMobile, formatDt, formatDtTime, formatSize } from "../../lib/utils";
import { calcPermissions } from "../../lib/shareFolder-utils";
import { __ } from "../../lib/translate";
import {
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
  SELECT,
  NAME,
  NAME_SUB,
  DESCRIPTION,
  ACTIONS_COLUMN,
  SIZE,
  CREATED,
  MODIFIED,
  DELETED,
  TYPE_ICON,
  ARTIST,
  ALBUM,
  GENRE,
  FILE_SECTION,
  SHARE_SECTION,
  ARCHIVE_SECTION,
  DOWNLOAD_SECTION,
  DELETE_SECTION,
  EDIT_SECTION,
  INFO_SECTION,
  SHARE_SUBSECTION,
  ADDTO_SUBSECTION,
  ALBUMS_COUNT,
  SONGS_COUNT,
  ITEMS,
  COMMENT,
  GRID_VIEW,
  GRID_ASPECT_RATIO_VIEW,
  LIST_VIEW,
  LARGE_LIST_VIEW,
  SORT,
  TRAFFIC,
  DOWNLOADS,
  SHARES_COUNT,
  OWNERNAME,
  PERMISSIONS,
  TYPE,
  BROWSE_CONTENT,
  BROWSE_PHOTOSEARCH_RESULTS,
  BROWSE_DELETED_CONTENT,
  BROWSE_REVISION_CONTENT,
  BROWSE_SONGS,
  BROWSE_ARTIST,
  BROWSE_ARTISTS,
  BROWSE_ALBUM,
  BROWSE_ALBUMS,
  BROWSE_PLAYLIST,
  BROWSE_PLAYLISTS,
  BROWSE_CONTENT_REWIND,
  BROWSE_BOOKMARKS,
  BROWSE_FILEREQUESTS,
  BROWSE_SHAREDLINKS,
  BROWSE_MYSHARES,
  BROWSE_SHAREDWITHME,
  REVISIONS, REVISIONID
} from "../../config/constants";
import hashManager from "../../lib/hashManager";

export type SortDirection = typeof SORT_DIRECTION_ASC | typeof SORT_DIRECTION_DESC | null;

export type Sort = {
  sortBy: typeof NAME,
  sortDirection: SortDirection,
  folderFirst: boolean,
  sortType: string
};

export const DEFAULT_SORT: Sort = {
  sortBy: NAME,
  sortDirection: SORT_DIRECTION_ASC,
  folderFirst: true,
  sortType: 'text'
}

export type ColumnTypes =
  typeof SELECT
  | typeof NAME
  | typeof SIZE
  | typeof MODIFIED
  | typeof DELETED
  | typeof TYPE_ICON
  | typeof ARTIST
  | typeof ALBUM
  | typeof ALBUMS_COUNT
  | typeof SONGS_COUNT
  | typeof GENRE
  | typeof SHARES_COUNT;

export type ActionButtonTypes =
  typeof COMMENTS_BUTTON
  | typeof INVITE_BUTTON
  | typeof DIRECT_LINK_SHARE_MENU_BUTTON
  | typeof STOP_BACKUP_BUTTON
  | typeof SHARE_MENU_BUTTON
  | typeof OPEN_DOCUMENT_BUTTON;

export type SectionIDs =
  typeof FILE_SECTION
  | typeof SHARE_SECTION
  | typeof ARCHIVE_SECTION
  | typeof DOWNLOAD_SECTION
  | typeof DELETE_SECTION
  | typeof EDIT_SECTION
  | typeof INFO_SECTION;

export type SubSectionIDs = typeof ADDTO_SUBSECTION | typeof SHARE_SUBSECTION;

export type contentTypes = typeof BROWSE_CONTENT
  | typeof BROWSE_PHOTOSEARCH_RESULTS
  | typeof BROWSE_DELETED_CONTENT
  | typeof BROWSE_REVISION_CONTENT
  | typeof BROWSE_SONGS
  | typeof BROWSE_ARTIST
  | typeof BROWSE_ARTISTS
  | typeof BROWSE_ALBUM
  | typeof BROWSE_ALBUMS
  | typeof BROWSE_PLAYLIST
  | typeof BROWSE_PLAYLISTS
  | typeof BROWSE_CONTENT_REWIND
  | typeof BROWSE_BOOKMARKS
  | typeof BROWSE_FILEREQUESTS
  | typeof BROWSE_SHAREDLINKS
  | typeof BROWSE_MYSHARES
  | typeof BROWSE_SHAREDWITHME
  ;

export type ColumnsObjType = Array<{
  sortable: boolean,
  columnTitle: string | null,
  tooltip?: string,
  width?: string,
  stretchWidth?: boolean,
  id: ColumnTypes,
  contentKey?: string,
  sortType?: string,
  innerColumns?: ColumnsObjType,
  getFieldValue?: (data: any) => any,
  visibleOnHover?: boolean
}>;

export type TemplatesType = {
  [content: contentTypes]: {
    VIEWS_MENU: array,
    BREADCRUMB?: boolean,
    SORTING_MENU?: boolean,
    onClick?: (data: any) => any,
    getItemName?: (data: any) => any,
    COLUMNS: ColumnsObjType
  }
};

const getMetaName = (meta) => {
  return HFN.metaName(meta);
}

const getSongTitle = (data) => {
  return data.title ? data.title : data.name;
}

const getAlbumsCount = (data) => {
  return (data.albums_count || 0) + " " + __("Albums").toLowerCase();
}

const getSongsCount = (data) => {
  return (data.songs_count || data.items || 0) + " " + __("Songs").toLowerCase();
}

const getAlbumsSongsCount = (data) => {
  return getAlbumsCount(data) + ", " + getSongsCount(data);
}

const onItemClick = (event, data) => {
  if (event.ctrlKey || event.shiftKey || event.metaKey) {
    return;
  }

  event.stopPropagation();
  event.preventDefault();

  const parent = event.currentTarget;
  const contentId = parent.getAttribute("data-id");

  if (data.isfolder) {
    HFN.openFolder(data.folderid);
  } else {
    if (hashManager.getState('page') === 'trash') {
      return;
    }
    if (HFN.config.isCollaborationEnabled() && HFN.collaborationFileTypes.has(data.contenttype)) {
      HFN.openCollaborationDocument(data, function (data, error) {
        HFN.previewFile(data);
      });
    } else {
      HFN.previewFile(data, null, null, detectIsMobile() ? false : true);
    }
  }
}

const onSharedWithMeItemClick = (event, data) => {
  if (data.sharerequestid || event.ctrlKey || event.shiftKey || event.metaKey) {
    return;
  }

  event.stopPropagation();
  event.preventDefault();

  hashManager.pushState({ page: 'filemanager', 'folder': data.folderid }, 2);
}

export const getItemClickActionAndLabel = (item, forceDownloadItemCategories) => {
  // Define click action.
  const onClick = (event, item, hashParams) => {
    if (item.isfolder) {
      HFN.openFolder(item.folderid, undefined, hashParams);
    } else if (HFN.config.isCollaborationEnabled() && HFN.collaborationFileTypes.has(item.contenttype)) {
      HFN.openCollaborationDocument(item, function (item, error) {
        HFN.previewFile(item, undefined, undefined, undefined, forceDownloadItemCategories);
      });
    } else {
      HFN.previewFile(item, undefined, undefined, undefined, forceDownloadItemCategories);
    }
  };

  // Define label.
  let label = "Download";
  if (item.isfolder) {
    label = "Open";
  } else {
    const ext = fileext(item.name).toLowerCase();

    switch (item.category) {
      case HFN.CATEGORY.IMAGE:
      case HFN.CATEGORY.VIDEO:
        label = "Open preview";
        break;
      case HFN.CATEGORY.AUDIO:
        if (HFN.config.excludeAudioExt.indexOf(fileext(item.name).toLowerCase()) == -1) {
          label = "Play";
        } else {
          label = "Download";
        }
        break;
      case HFN.CATEGORY.ARCHIVE:
        if (forceDownloadItemCategories && forceDownloadItemCategories[HFN.CATEGORY.ARCHIVE]) {
          label = "Download";
        } else {
          label = "Open";
        }
        break;
      default:
        if (ext == 'pdf' || HFN.docPreview.canPreview(item)) {
          label = "Open preview";
        } else if (HFN.textView.is_readable(ext) && item.size < (1024 * 512)) {
          label = "Open";
        }
    }
  }

  return { label, onClick }
};

const onSongClick = (event, data) => {
  if (event.ctrlKey || event.shiftKey || event.metaKey) {
    return;
  }

  HFN.audioPlayer.play([data], data);
};

const onArtistClick = (event, data) => {
  if (event.ctrlKey || event.shiftKey || event.metaKey)
    return;

  event.stopPropagation();

  hashManager.pushState({ page: 'audio', autab: 'tab-artist', artist: data.name }, 2);
};

const onAlbumClick = (event, data) => {
  if (event.ctrlKey || event.shiftKey || event.metaKey)
    return;

  event.stopPropagation();

  hashManager.pushState({ page: 'audio', autab: 'tab-album', artist: data.artist, album: data.name });
};

const onPlaylistClick = (event, data) => {
  if (event.ctrlKey || event.shiftKey || event.metaKey)
    return;

  event.stopPropagation();

  hashManager.pushState({ page: 'audio', autab: 'tab-playlist', playlist: data.id }, 2);
};

const onLinkClick = (event, data) => {
  if (event.ctrlKey || event.shiftKey || event.metaKey) {
    return;
  }

  window.open(data.link, "_blank");
};

const onFileRequestClick = (event, data) => {
  if (event.ctrlKey || event.shiftKey || event.metaKey) {
    return;
  }

  hashManager.pushState({ page: 'filemanager', 'folder': data.metadata.folderid }, 2);
};

const onMyShareClick = (event, data) => {
  if (event.ctrlKey || event.shiftKey || event.metaKey) {
    return;
  }

  if (data.isfolder && data.folderid != 0) {
    hashManager.pushState({ folder: data.folderid, page: 'filemanager' }, 2);
  } else if (data.type == 'virtual') {
    HFN.previewVirtualFolder(data);
  } else if (data.type == 'collection') {
    hashManager.pushState({ page: 'audio', autab: 'tab-playlist', playlist: data.id.substring(1) });
  } else {
    hashManager.pushState({ folder: data.parentfolderid, page: 'filemanager', file: data.id });
  }
};

const selectColumn = {
  sortable: false,
  clickable: true,
  columnTitle: null,
  stretchWidth: false,
  visibleOnHover: true,
  showOnMobile: true
};

const nameColumn = {
  sortable: true,
  columnTitle: ["Name"],
  stretchWidth: true,
  contentKey: "name",
  getFieldValue: getMetaName,
  sortType: "text",
  showOnMobile: true
};

const typeIconColumn = {
  sortable: false,
  clickable: true,
  columnTitle: null,
  onClick: onItemClick,
  showOnMobile: true,
  showSelectByType: true
};

const nameSubColumn = {
  sortable: false,
  clickable: true,
  columnTitle: null,
  stretchWidth: true,
  contentKey: "name",
  getFieldValue: getMetaName,
  onClick: onItemClick,
  showOnMobile: true
};

const actionsColumn = {
  sortable: false,
  columnTitle: null,
  visibleOnHoverOnly: true,
  showOnMobile: true
};

const modifiedColumn = {
  sortable: true,
  clickable: false,
  columnTitle: ["Modified"],
  stretchWidth: false,
  contentKey: "modified",
  sortType: "date",
  formatMethod: formatDt,
};

const createdColumn = {
  sortable: true,
  clickable: false,
  columnTitle: ["Created"],
  stretchWidth: false,
  contentKey: "created",
  sortType: "date",
  formatMethod: formatDtTime,
};

const revisionColumn = {
  sortable: true,
  folderFirst: true,
  clickable: false,
  columnTitle: ["Revision"],
  stretchWidth: false,
  contentKey: "revisionid",
  sortType: "text",
};

const sizeColumn = {
  sortable: true,
  clickable: false,
  columnTitle: ["File size"],
  stretchWidth: false,
  contentKey: "size",
  sortType: "number",
  formatMethod: formatSize
}

const albumColumn = {
  sortable: true,
  clickable: true,
  columnTitle: ["Album"],
  stretchWidth: false,
  contentKey: "album",
  sortType: "text",
  onClick: function (event, data) {
    hashManager.pushState({ page: 'audio', autab: 'tab-album', album: data.album }, 2);
  },
  ellipsisTooltip: 25
}

const genreColumn = {
  sortable: true,
  clickable: false,
  columnTitle: ["Genre"],
  stretchWidth: false,
  contentKey: "genre",
  sortType: "text",
  ellipsisTooltip: 25
}

const songsCount = {
  sortable: true,
  clickable: false,
  columnTitle: ["Songs"],
  stretchWidth: false,
  getFieldValue: getSongsCount,
  contentKey: "songs_count",
  sortKey: "songs_count",
  sortType: "number"
}

const artistColumn = {
  sortable: true,
  clickable: true,
  columnTitle: ["Artist"],
  stretchWidth: false,
  contentKey: "artist",
  sortType: "text",
  onClick: function (event, data) {
    hashManager.pushState({ page: 'audio', autab: 'tab-artist', artist: data.artist.trim() }, 2);
  }
}

const songNameColumn = {
  ...nameColumn,
  getFieldValue: getSongTitle,
  innerColumns: {
    [TYPE_ICON]: {
      ...typeIconColumn,
      onClick: onSongClick,
    },
    [NAME_SUB]: {
      ...nameSubColumn,
      getFieldValue: getSongTitle,
      onClick: onSongClick,
      secondLine: {
        secGetFieldValue: (data) => {
          return data.artist || "-";
        },
        secShowOnMobileOnly: true
      },
      ellipsisTooltip: 45
    },
    [ACTIONS_COLUMN]: actionsColumn
  }
}

export const TEMPLATES: TemplatesType = {
  [BROWSE_CONTENT]: {
    VIEWS_MENU: [GRID_VIEW, GRID_ASPECT_RATIO_VIEW, LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: GRID_VIEW,
    HEADER_PROMO_BLOCKS: true,
    BREADCRUMB: true,
    SORTING_MENU: true,
    COLUMNS: {
      [SELECT]: selectColumn,
      [TYPE_ICON]: typeIconColumn,
      [NAME]: {
        ...nameColumn,
        innerColumns: {
          [NAME_SUB]: {
            ...nameSubColumn,
            secondLine: {
              secPrefix: ["Modified"],
              secContentKey: "modified",
              secFormatMethod: formatDt,
              secShowOnMobileOnly: true
            }
          },
          [ACTIONS_COLUMN]: actionsColumn
        },
      },
      [MODIFIED]: modifiedColumn,
      [SIZE]: sizeColumn
    },
    onClick: onItemClick,
    getItemName: getMetaName,
    noItemsLabel: ["Empty folder"]
  },
  [BROWSE_PHOTOSEARCH_RESULTS]: {
    VIEWS_MENU: [GRID_VIEW, GRID_ASPECT_RATIO_VIEW],
    DEFAULT_VIEW: GRID_ASPECT_RATIO_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: false,
    COLUMNS: [],
    onClick: onItemClick,
    noItemsLabel: ["search_photos_no_results_title", "No results"]
  },
  [BROWSE_DELETED_CONTENT]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    HEADER_PROMO_BLOCKS: true,
    BREADCRUMB: true,
    SORTING_MENU: true,
    COLUMNS: {
      [SELECT]: selectColumn,
      [NAME]: {
        ...nameColumn,
        innerColumns: {
          [TYPE_ICON]: typeIconColumn,
          [NAME_SUB]: {
            ...nameSubColumn,
            secondLine: {
              secPrefix: ["Deleted"],
              secContentKey: "modified",
              secFormatMethod: formatDt,
              secShowOnMobileOnly: true
            }
          },
          [ACTIONS_COLUMN]: actionsColumn
        }
      },
      [MODIFIED]: {
        ...modifiedColumn,
        columnTitle: ["Deleted"],
      },
      [SIZE]: sizeColumn
    },
    onClick: onItemClick,
    getItemName: getMetaName,
    noItemsLabel: ["trash_empty_title", "You don’t have any deleted files"]
  },
  [BROWSE_REVISION_CONTENT]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    DISABLE_MULTISELECT: true,
    HEADER_PROMO_BLOCKS: true,
    BREADCRUMB: true,
    SORTING_MENU: true,
    DISABLE_NAME_SORT: true,
    USE_DATE_CREATED_SORT: true,
    DISABLE_FOLDERS_FIRST: true,
    DEFAULT_SORT: {
      sortBy: REVISIONID,
      sortDirection: SORT_DIRECTION_ASC,
      folderFirst: true,
      sortType: 'text'
    },
    COLUMNS: {
      [NAME]: {
        ...nameColumn,
        innerColumns: {
          [TYPE_ICON]: typeIconColumn,
          [NAME_SUB]: {
            ...nameSubColumn,
            secondLine: {
              secPrefix: ["Revision"],
              secContentKey: "revisionid",
              secShowOnMobileOnly: true,
              secShowColon: true
            }
          },
          [ACTIONS_COLUMN]: actionsColumn
        }
      },
      [REVISIONID]: revisionColumn,
      [SIZE]: sizeColumn,
      [CREATED]: {
        ...createdColumn,
        customClass: "created-col-with-time created-col-right",
      },
    },
    onClick: onItemClick,
    getItemName: getMetaName,
    noItemsLabel: ["revision_empty_title", "You don’t have any revisions"]
  },
  [BROWSE_SONGS]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: true,
    COLUMNS: {
      [SELECT]: selectColumn,
      [NAME]: songNameColumn,
      [ARTIST]: {
        ...artistColumn,
        ellipsisTooltip: 25
      },
      [ALBUM]: albumColumn,
      [GENRE]: genreColumn,
    },
    onClick: onItemClick,
    noItemsLabel: ["songs_empty_title", "It seems that you did not upload any audio files in your pCloud account yet."]
  },
  [BROWSE_ARTISTS]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: true,
    COLUMNS: {
      [SELECT]: selectColumn,
      [NAME]: {
        ...nameColumn,
        innerColumns: {
          [TYPE_ICON]: {
            ...typeIconColumn,
            onClick: onArtistClick,
          },
          [NAME_SUB]: {
            ...nameSubColumn,
            onClick: onArtistClick,
            secondLine: {
              secGetFieldValue: getAlbumsSongsCount,
              secShowOnMobileOnly: true
            },
            ellipsisTooltip: 50
          },
          [ACTIONS_COLUMN]: actionsColumn
        }
      },
      [ALBUMS_COUNT]: {
        sortable: true,
        clickable: false,
        columnTitle: ["Albums"],
        stretchWidth: false,
        getFieldValue: getAlbumsCount,
        contentKey: "albums_count",
        sortKey: "albums_count",
        sortType: "number"
      },
      [SONGS_COUNT]: songsCount
    },
    onClick: onArtistClick,
    noItemsLabel: ["artists_empty_title", "No artists"]
  },
  [BROWSE_ARTIST]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: true,
    COLUMNS: {
      [SELECT]: selectColumn,
      [NAME]: songNameColumn,
      [ALBUM]: albumColumn,
      [GENRE]: genreColumn
    },
    noItemsLabel: ["No songs found"]
  },
  [BROWSE_ALBUMS]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW, GRID_VIEW],
    DEFAULT_VIEW: GRID_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: true,
    COLUMNS: {
      [SELECT]: selectColumn,
      [NAME]: {
        ...nameColumn,
        innerColumns: {
          [TYPE_ICON]: {
            ...typeIconColumn,
            onClick: onAlbumClick,
          },
          [NAME_SUB]: {
            ...nameSubColumn,
            onClick: onAlbumClick,
            ellipsisTooltip: 50,
            secondLine: {
              secContentKey: "artist",
              secShowOnMobileOnly: true,
              onClick: onAlbumClick
            }
          },
          [ACTIONS_COLUMN]: actionsColumn
        },
      },
      [ARTIST]: {
        ...artistColumn,
        ellipsisTooltip: 40,
      },
      [SONGS_COUNT]: songsCount
    },
    onClick: onAlbumClick,
    noItemsLabel: ["albums_empty_title", "No albums"]
  },
  [BROWSE_ALBUM]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: true,
    COLUMNS: {
      [SELECT]: selectColumn,
      [NAME]: {
        ...songNameColumn,
        ellipsisTooltip: 50
      },
      [GENRE]: genreColumn
    },
    onClick: onItemClick,
    noItemsLabel: ["Empty album"]
  },
  [BROWSE_PLAYLISTS]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    DISABLE_SELECTO: true,
    DISABLE_MULTISELECT: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: true,
    COLUMNS: {
      [NAME]: {
        ...nameColumn,
        innerColumns: {
          [TYPE_ICON]: {
            ...typeIconColumn,
            onClick: onPlaylistClick,
          },
          [NAME_SUB]: {
            ...nameSubColumn,
            onClick: onPlaylistClick,
            secondLine: {
              secGetFieldValue: getSongsCount,
              secShowOnMobileOnly: true
            },
            ellipsisTooltip: 50
          },
          [ACTIONS_COLUMN]: actionsColumn
        }
      },
      [ITEMS]: {
        ...songsCount,
        contentKey: "items",
        sortKey: "items",
        getFieldValue: undefined
      },
      [MODIFIED]: modifiedColumn,
      [CREATED]: {
        ...modifiedColumn,
        columnTitle: ["Created"],
        contentKey: "created",
      },
    },
    onClick: onPlaylistClick,
    noItemsLabel: ["playlists_empty_title", "No playlists created"]
  },
  [BROWSE_PLAYLIST]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: true,
    COLUMNS: {
      [SELECT]: selectColumn,
      [NAME]: songNameColumn,
      [ARTIST]: {
        ...artistColumn,
        ellipsisTooltip: 15
      },
      [GENRE]: genreColumn
    },
    onClick: onItemClick,
    noItemsLabel: ["Empty playlist"]
  },
  [BROWSE_CONTENT_REWIND]: {
    VIEWS_MENU: [GRID_VIEW, GRID_ASPECT_RATIO_VIEW, LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    HEADER_PROMO_BLOCKS: true,
    BREADCRUMB: true,
    SORTING_MENU: true,
    COLUMNS: {
      [SELECT]: selectColumn,
      [NAME]: {
        ...nameColumn,
        innerColumns: {
          [TYPE_ICON]: typeIconColumn,
          [NAME_SUB]: {
            ...nameSubColumn,
            secondLine: {
              secPrefix: ["Modified"],
              secContentKey: "modified",
              secFormatMethod: formatDt,
              secShowOnMobileOnly: true
            }
          },
          [ACTIONS_COLUMN]: actionsColumn
        }
      },
      [MODIFIED]: modifiedColumn,
      [SIZE]: sizeColumn
    },
    onClick: onItemClick,
    getItemName: getMetaName,
    noItemsLabel: ["Empty folder"]
  },
  [BROWSE_BOOKMARKS]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: true,
    COLUMNS: {
      [SELECT]: selectColumn,
      [NAME]: {
        ...nameColumn,
        innerColumns: {
          [TYPE_ICON]: {
            ...typeIconColumn,
            onClick: onLinkClick,
          },
          [NAME_SUB]: {
            ...nameSubColumn,
            onClick: onLinkClick,
            secondLine: {
              secPrefix: ["Created"],
              secContentKey: "modified",
              secFormatMethod: formatDt,
              secShowOnMobileOnly: true
            }
          },
          [ACTIONS_COLUMN]: actionsColumn
        }
      },
      [DESCRIPTION]: {
        sortable: true,
        clickable: false,
        columnTitle: ['general_branding_description', 'Description'],
        stretchWidth: false,
        contentKey: "description",
        getFieldValue: (item) => {
          return item.description ? item.description : "-";
        },
        ellipsisTooltip: 25,
        sortType: "text"
      },
      [MODIFIED]: {
        ...modifiedColumn,
        columnTitle: ["Created"],
        customClass: "bookmarks"
      },
    },
    onClick: onLinkClick,
    noItemsLabel: ["No bookmarks found"]
  },
  [BROWSE_FILEREQUESTS]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: true,
    COLUMNS: {
      [SELECT]: selectColumn,
      [NAME]: {
        ...nameColumn,
        innerColumns: {
          [TYPE_ICON]: {
            ...typeIconColumn,
            onClick: onFileRequestClick,
          },
          [NAME_SUB]: {
            ...nameSubColumn,
            onClick: onFileRequestClick,
            secondLine: {
              secPrefix: ["Created"],
              secContentKey: "modified",
              secFormatMethod: formatDt,
              secShowOnMobileOnly: true
            }
          },
          [ACTIONS_COLUMN]: actionsColumn
        }
      },
      [COMMENT]: {
        sortable: true,
        clickable: false,
        columnTitle: ["Comment"],
        stretchWidth: false,
        contentKey: "comment",
        getFieldValue: (item) => {
          return item.comment ? item.comment : "-";
        },
        ellipsisTooltip: 25,
        sortType: "text"
      },
      [MODIFIED]: {
        ...modifiedColumn,
        columnTitle: ["Created"],
        customClass: "bookmarks"
      },
    },
    onClick: onFileRequestClick,
    noItemsLabel: ["No file requests found"]
  },
  [BROWSE_SHAREDLINKS]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: true,
    USE_DATE_CREATED_SORT: true,
    COLUMNS: {
      [SELECT]: selectColumn,
      [NAME]: {
        ...nameColumn,
        columnTitle: ["Linked Item"],
        innerColumns: {
          [TYPE_ICON]: {
            ...typeIconColumn,
            onClick: onLinkClick,
          },
          [NAME_SUB]: {
            ...nameSubColumn,
            onClick: onLinkClick,
            secondLine: {
              secPrefix: ["Created"],
              secContentKey: "created",
              secFormatMethod: formatDt,
              secShowOnMobileOnly: true
            }
          },
          [ACTIONS_COLUMN]: actionsColumn
        }
      },
      [TRAFFIC]: {
        ...sizeColumn,
        columnTitle: ["Traffic"],
        contentKey: "traffic",
        getFieldValue: (data) => {
          return data.traffic;
        },
        hideBelowWidth: 550
      },
      [DOWNLOADS]: {
        sortable: true,
        clickable: false,
        columnTitle: ["Downloads"],
        stretchWidth: false,
        contentKey: "downloads",
        sortKey: "downloads",
        sortType: "number",
        getFieldValue: (data) => {
          return data.downloads;
        },
        hideBelowWidth: 600
      },
      [CREATED]: {
        ...modifiedColumn,
        contentKey: "created",
        columnTitle: ["Created"],
        customClass: "created-col-right",
      },
    },
    onClick: onLinkClick,
    noItemsLabel: ["publiclinks_empty_title", "You don't have any shared links"]
  },
  [BROWSE_MYSHARES]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    DISABLE_SELECTO: true,
    DISABLE_MULTISELECT: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: true,
    COLUMNS: {
      [NAME]: {
        ...nameColumn,
        columnTitle: ["Linked Item"],
        innerColumns: {
          [TYPE_ICON]: {
            ...typeIconColumn,
            onClick: onMyShareClick,
          },
          [NAME_SUB]: {
            ...nameSubColumn,
            onClick: onMyShareClick,
            secondLine: {
              secContentKey: "shares_count",
              secFormatMethod: (count) => {
                return count + " " + __("Shares").toLowerCase();
              },
              secShowOnMobileOnly: true
            }
          },
          [ACTIONS_COLUMN]: actionsColumn
        }
      },
      [SHARES_COUNT]: {
        sortable: true,
        clickable: false,
        columnTitle: ["Shares"],
        stretchWidth: false,
        contentKey: "shares_count",
        sortKey: "shares_count",
        sortType: "number"
      }
    },
    onClick: onMyShareClick,
    noItemsLabel: ["myshares_empty_title", "You haven't shared anything yet"]
  },
  [BROWSE_SHAREDWITHME]: {
    VIEWS_MENU: [LIST_VIEW, LARGE_LIST_VIEW],
    DEFAULT_VIEW: LIST_VIEW,
    DISABLE_FOLDERS_FIRST: true,
    DISABLE_SELECTO: true,
    DISABLE_MULTISELECT: true,
    HEADER_PROMO_BLOCKS: false,
    BREADCRUMB: false,
    SORTING_MENU: true,
    USE_DATE_CREATED_SORT: true,
    DEFAULT_SORT: {
      sortBy: TYPE,
      sortDirection: SORT_DIRECTION_ASC,
      folderFirst: true,
      sortType: 'text'
    },
    COLUMNS: {
      [NAME]: {
        ...nameColumn,
        columnTitle: ["Shared folder"],
        innerColumns: {
          [TYPE_ICON]: {
            ...typeIconColumn,
            onClick: onSharedWithMeItemClick,
            getIsClickable: (link) => {
              return link.sharerequestid ? false : true;
            },
          },
          [NAME_SUB]: {
            ...nameSubColumn,
            onClick: onSharedWithMeItemClick,
            getIsClickable: (link) => {
              return link.sharerequestid ? false : true;
            },
            secondLine: {
              secPrefix: ["Owner"], 
              secContentKey: OWNERNAME,
              secShowOnMobileOnly: true
            }
          },
          [ACTIONS_COLUMN]: actionsColumn,
          [TYPE]: {
            sortable: true,
            clickable: true,
            columnTitle: ["Actions"],
            stretchWidth: false,
            contentKey: "type",
            sortKey: "type",
            sortType: "text",
            showOnMobileOnly: true
          },
        }
      },
      [TYPE]: {
        sortable: true,
        clickable: true,
        columnTitle: ["Actions"],
        stretchWidth: false,
        contentKey: "type",
        sortKey: "type",
        sortType: "text"
      },
      [OWNERNAME]: {
        sortable: true,
        getIsClickable: (link) => {
          return (link.atype === 'business');
        },
        columnTitle: ["Owner"],
        stretchWidth: false,
        contentKey: OWNERNAME,
        sortKey: OWNERNAME,
        sortType: "text",
        onClick: (event, data) => {
          if (event.ctrlKey || event.shiftKey || event.metaKey) {
            return;
          }

          event.stopPropagation();
          event.preventDefault();

          hashManager.pushState({ page: 'b_user', id: data.userobj.id });
        },
        ellipsisTooltip: 20
      },
      [PERMISSIONS]: {
        sortable: false,
        clickable: false,
        columnTitle: ["My Permissions"],
        stretchWidth: false,
        contentKey: PERMISSIONS,
        sortKey: PERMISSIONS,
        getFieldValue: (link) => {
          const permVals = { 0: 'can view', 7: 'can edit', 15: 'can manage' };
          const permissions = calcPermissions(link);

          return __(permVals[permissions]);
        },
        hideBelowWidth: 640
      },
      [CREATED]: {
        ...modifiedColumn,
        columnTitle: ["Created"],
        contentKey: "created",
        customClass: "created-col-right-small",
        hideBelowWidth: 850
      },
    },
    onClick: onSharedWithMeItemClick,
    noItemsLabel: ["There are still no folders shared with you"]
  },
};

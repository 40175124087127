// @flow

import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

export const WarningIcon = styled.div`
  display: block;
  margin: 15px;
  width: 22px;
  height: 19px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/2fa-login/attention.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/attention@2x.png")});
  }

  &.important-icon {
    display: block;
    margin: 0 auto;
    height: 45%;
  }

  &.understand-icon {
    height: 35%;
  }

  @media only screen and (max-width: 425px) {
    margin: 10px;
  }
`;

export const UpgradeIcon = styled(WarningIcon)`
  width: 54px;
  height: 54px;

  background-image: url(${require("../../../root/img/2fa-login/2fa.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/2fa@2x.png")});
  }
`;

export const OkIcon = styled(WarningIcon)`
  display: inline-block;
  margin: 15px 0 30px 0;
  width: 42px;
  height: 42px;

  background-image: url(${require("../../../root/img/2fa-login/ok.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/ok@2x.png")});
  }

  @media only screen and (max-width: 425px) {
    margin: 30px;
  }
`;

export const Container = styled.div`
  text-align: center;
  border-radius: 21px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  padding: 30px;
  background: #fefefe;
  max-width: 560px;
  width: 480px;
  min-width: 425px;
  min-height: auto;
  font-family: "Roboto", sans-serif;
  color: #333;
  box-sizing: border-box;

  input:focus {
    outline: none;
  }

  &.security-container {
    /* padding: 40px 0 30px 0; */
    padding: 30px;
    div.important-message {
      height: auto;
    }
  }

  &.sent-email {
    height: 228px;
    min-height: auto;
    border-radius: 21px;
  }

  a {
    min-width: 155px;
    height: 38px;
  }

  .download {
    text-decoration: underline;
    color: #57bbcd;
  }

  .phone-number {
    display: block;
    margin-top: 20px;
    font-size: 17px;
    font-weight: bold;
    color: black;
  }

  p {
    /*font-size: 13px;
    color: #999;
    padding: 0 40px;*/
    font-size: 15px;
    color: #000;
    padding: 0px 15px;
    font-family: "Roboto", sans-serif;
    text-align: center;
  }

  .subheading-security-codes {
    padding: 0px 65px;
  }

  .security-buttons {
    padding: 0px 40px;

    a {
      width: 235px;
      padding: 0 10px;
      display: inline-block;
    }
  }

  .selected-dial-code {
    padding-left: 10px !important;
  }

  @media only screen and (max-width: 560px) {
    width: 90vw;
    min-width: 0;

    .subheading-security-codes {
      padding: 0 20px;
    }

    .security-buttons {
      padding: 0px;
      height: 100%;

      a {
        font-size: 12px;
        margin: 5px;
        width: 280px;
        float: none;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    p {
      padding: 0 10px;
    }

    .react-tel-input {
      .phone-input {
        width: 300px;
      }
    }

    .intl-tel-input {
      input[type="tel"] {
        width: 300px;
      }
    }

    .subheading-security-codes {
      padding: 0;
      justify-content: center;
    }

    a {
      width: 300px;
    }

    .security-buttons {
      padding: 0px;
      height: 50px;

      a {
        font-size: 12px;
        margin: 5px;
        width: 300px;
        float: none;
      }
    }
  }
`;

export const Header = styled.div`
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  margin-top: -3px;
  padding-bottom: 15px;

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.off {
    padding: 0px 26px;
  }

  @media only screen and (max-width: 425px) {
    font-size: 15px;

    &.off {
      margin: 20px 0;
      padding: 0 10px;
    }
  }
`;

export const Message = styled.div`
  font-size: 13px;
  line-height: 1.2;
  box-sizing: border-box;
  margin: 30px 50px 35px 50px;

  &.msg-text {
    height: 50%;
  }

  a {
    color: #17bed0;
    padding: 0 4px;
  }

  p {
    padding-top: 10px;
  }

  &.require-pass,
  &.verification-code {
    margin: 15px 0 30px 0;
  }
  &.require-pass {
    input {
      width: 100%;
      box-shadow: inset 0 3px 2px 0 rgb(41 38 38 / 5%);
      border: var(--border-size) solid var(--border-primary);
      border-radius: 5px;
    }
  }

  &.change-phone {
    /* margin: 56px 50px 30px 50px; */
    padding: 14px 0px 6px 0px;
    clear: both;
    margin: 0;
    font-size: 15px;
    color: #000;
}
  }

  &.important-message {
    /*border: 1px solid #fdf0db;
    background-color: #fffcf1;*/
    height: 116px;
    padding: 0 40px;
    border: 1px solid #eee;
    background-color: #fafafa;
    border-radius: 6px;
  }

  &.setup-pass {
    padding: 0 40px;
  }

  &.setup-pass-form-msg {
    /* margin: 30px 50px 48px 50px; */
    margin: 10px 0;
    input {
      width: 100%;
      line-height: normal;
      height: 40px;
      padding: 0 10px;
      border: 1px solid #ccc;
      font-size: 14px;
      border-radius: 5px;
      box-sizing: border-box;
    }
  }

  &.understand {
    padding: 10px 40px;
    font-size: 15px;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  &.email-message {
    /* padding: 30px;
    height: auto; */
    font-size: 15px;

    @media only screen and (max-width: 425px) {
      margin: 30px 0 35px 0;
    }
  }

  &.phone-number-msg {
    /* margin: 50px 40px 69px 40px; */
    margin: 15px 0px 31px 0px;
  }

  &.security {
    display: block;
    /* padding: 12px 20px; */
    padding: 0;
    color: #999;
    font-size: 13px;
    margin: 30px;
  }

  &.view {
    /* padding: 24px 20px;
    height: 132px; */
    margin: 0;
    padding: 0;
    height: auto;
  }

  .react-tel-input {
    .flag-dropdown {
      border-color: #a1a1a1; 
      border-radius: 5px 0 0 5px;
      background-color: #fff;
      user-select: none;
    }

    .selected-flag {
      width: 44px;
      padding-left: 9px;
      border-radius: 5px 0 0 5px
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    .country-list {
      text-align: left;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
      margin-top: 0;
      width: auto;
      border: 1px solid #ccc;

      .flag {
        margin-right: 6px;
      }
      
      .country {
        display: flex;
        padding: 5px 10px;

        .country-name {
          white-space: nowrap;
          font-size: 13px;
        }

        .dial-code {
          font-size: 13px;
          color: #999;
        }

        &.highlight, &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }

    .phone-input {
      width: 100%;
      font-size: 13px;
      color: #000;
      border-radius: 5px;
      border: 1px solid #a1a1a1;
      outline: none;
      box-sizing: border-box;
      height: 38px;
      line-height: 30px;
      padding-left: 54px;
    }
  }

  .intl-tel-input {
    input {
      border: 1px solid #a1a1a1;
      width: 420px;
      border-radius: 5px;
      height: 38px;
      line-height: 30px;
      font-size: 13px;
      padding-left: 5px;
    }

    ul {
      white-space: normal;
    }
  }

  .security-codes {
    display: table;
    font-size: 15px;
    padding: 18px;
    color: #000;
  }

  .security-text {
    /*font-size: 11px;
    color: #686868;*/
    font-size: 13px;
    color: #686868;
    padding: 18px;
    border-bottom: 1px solid #eee;

    .no-link {
      color: #686868;
    }
  }

  @media only screen and (max-width: 560px) {
    min-width: 300px;
    font-size: 12px;
    margin: 20px;
    box-sizing: border-box;

    &.view {
      margin: 0;
    }

    &.require-pass,
    &.verification-code,
    &.phone-number-msg {
      /* margin: 20px; */
      margin: 20px 0;
    }

    &.email-message {
      padding: 30px;
      height: auto;
    }

    &.security {
      margin: 15px 40px;
      height: 145px;
    }

    .security-codes {
      justify-content: center;
    }
  }

  @media only screen and (max-width: 425px) {
    &.security {
      height: 155px;
      margin: 23px 10px;
    }
  }

  @media only screen and (max-width: 360px) {
    &.require-pass,
    &.phone-number-msg,
    &.verification-code,
    &.important-message {
      /* margin: 10px; */
      margin: 10px 0;
    }
  }
`;

export const ErrorMessage = styled.div`
  font-size: 11px;
  min-height: 19px;
  opacity: ${props => (props.show ? 1 : 0)};
  color: #ff2626;
`;

export const Code = styled.div`
  display: inline-table;
  width: 25%;
  padding-bottom: 10px;
  text-decoration: ${props => (props.used ? "line-through" : "none")};
  color: ${props => (props.used ? "#999" : "#000")};

  @media only screen and (max-width: 550px) {
    font-size: 12px;
    width: 25%;
  }
`;

export const Link = styled.div`
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  color: ${props => (props.shouldResendCode ? "#999" : "#57bbcd")};
  text-decoration: none;
  margin-top: 52px;
  cursor: ${props => (props.shouldResendCode ? "not-allowed" : "pointer")};
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 26px;
  height: 26px;
  margin: 12px;
  cursor: pointer;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${require("../../../root/img/2fa-login/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/close@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const BackButton = styled.div`
  position: absolute;
  top: -24px;
  left: 0;
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: #17bed0;
  cursor: pointer;
`;

export const InputPass = styled.input`
  width: ${({ width }) => (width ? width : "328px")};
  height: 38px;
  line-height: 26px;
  font-size: var(--font-size-14);
  padding-left: 10px;
  border: ${props => (props.shouldRenderRedBorder ? "1px solid #FF7474" : "var(--border-size) solid var(--border-primary)")};
  box-sizing: border-box;
  outline: none;
  
  @media (max-width: 500px) {
    width: 100%!important;
  }

  &.verification-input {
    /* width: 155px; */
    width: 100%;
    border-radius: 5px;
    border: var(--border-size) solid var(--border-primary);
  }

  &.setup-pass-form {
    margin-bottom: 5px;
  }

  @media only screen and (max-width: 425px) {
    width: 300px;

    &.verification-input {
      /* width: 300px; */
    }
  }
`;

export const InputCode = styled(InputPass)`
  width: 200px;
  font-size: 15px;
  letter-spacing: 6px;
  text-align: center;
  border: ${props => (props.shouldRenderRedBorder ? "1px solid #FF7474" : "1px solid #cccccc")};

  @media only screen and (max-width: 425px) {
    width: 300px;
  }
`;

const Pulsate = keyframes`
  0% {transform: scale(0.1, 0.1); opacity: 0;}
  50% {opacity: 1;}
  100% {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.2, 1.2);
    transform: translateZ(0);
    transform: scale(1.0, 1.0);
    opacity: 0;
    }
`;

export const Loader = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  border: 4px solid #20bed6;
  border-radius: 30px;
  animation: ${Pulsate} 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
`;

export const LoaderSecurityCodes = styled(Loader)`
  position: relative;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  margin: 0 auto 20px;
`;

export const LoaderTfaActivators = styled(Loader)`
  position: relative;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  margin: 80px auto 40px;
`;

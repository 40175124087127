// @flow

import { DEFAULT_SELECTED_ITEMS_INFO_TAB } from "../../../config/constants";
import type { Action } from "../actions/types";
import { LOCAL_STORAGE_TOGGLED } from "../../../components/LeftMenu/constants";
import {
  LOAD_CONTENT,
  DELETE_CONTENT_ITEMS,
  SET_CONTENT_ITEMS,
  ADD_CONTENT_ITEM,
  DELETE_CONTENT_ITEM,
  MODIFY_CONTENT_ITEM,
  SET_CONTENT_KEYS,
  SET_DEFAULT_BRANDING,
  LOAD_BREADCRUMB,
  DELETE_BREADCRUMB,
  SET_LEFT_MENU_CONTENT,
  SET_PAGE_INFO,
  SET_ACTIVE_EXPLORE_CARD,
  INCREMENT_EXPLORE_CARD,
  DECREMENT_EXPLORE_CARD,
  SET_GRID_HEADER_HEIGHT,
  SET_ITEMS_INFO_PANEL_DATA,
} from "../constants/content";
import hashManager from "../../hashManager";

type Item = {
  path: string,
  name: string,
  created: string,
  encrypted?: boolean,
  ismine: boolean,
  thumb: boolean,
  modified: string,
  id: string,
  isshared: boolean,
  icon: number,
  isfolder: boolean,
  folderid?: number,
  fileid?: number,
  sorting: string,
  contents: Array<string>,
};

type PageInfoItem = {
  name: string,
  page: string,
}

type PageInfo = {
  explorecards: PageInfoItem[],
  explorecardsleft: boolean,
  leftmenuitems: PageInfoItem[],
  leftprofilemenuitems: PageInfoItem[],
}

export type ContentState = {
  loaded: boolean,
  breadcrumb: Array<any>,
  itemData: {
    comments: 0,
    contents: Array<any>,
    created: string,
    folderid: string,
    icon: number,
    id: string,
    isfolder: boolean,
    ismine: boolean,
    isshared: boolean,
    modified: string,
    name: string,
    parentfolderid: number,
    thumb: boolean,
    items: {
      [id]: Item
    }
  },
  itemKeys: [],
  leftMenuContent: { activeItem: string, toggled: boolean, activeItemIndex: number, isProfileOpened: boolean, previousMainActiveItem: string, previousMainActiveItemIndex: number },
  gridHeaderHeight: number,
  activeExploreCardIndex: number,
  pageInfo: PageInfo,
};

const initialState = {
  loaded: false,
  breadcrumb: [],
  itemData: {
    comments: 0,
    contents: [],
    created: '',
    folderid: '',
    icon: 0,
    id: '',
    isfolder: false,
    ismine: false,
    isshared: false,
    modified: '',
    name: '',
    parentfolderid: 0,
    thumb: false,
    items: {}
  },
  itemKeys: [],
  leftMenuContent: {
    activeItem: "",
    toggled: localStorage.getItem(LOCAL_STORAGE_TOGGLED) === 'true',
    activeItemIndex: -1,
    isProfileOpened: false,
    previousMainActiveItem: "",
    previousMainActiveItemIndex: -1,
    isMobileMenuOpened: false
  },
  activeExploreCardIndex: 1,
  gridHeaderHeight: 0,
  pageInfo: {
    explorecards: [],
    explorecardsleft: false,
    leftmenuitems: [],
    leftprofilemenuitems: [],
  },
  itemsInfoPanelData: {
    show: !!hashManager.getState('comments'),
    tab: !!hashManager.getState('comments') ? "comments" : DEFAULT_SELECTED_ITEMS_INFO_TAB,
    selectedItemsCount: 1,
    selectedItem: null,
    autoFocus: {
      value: false
    }
  }
};

const setContentItems = (metadata) => {
  const {
    hash = "",
    comments = 0,
    contents = [],
    created = '',
    folderid = '',
    fileid = '',
    icon = 0,
    id = '',
    isfolder = false,
    ismine = false,
    isshared = false,
    isalbum = false,
    isplaylist = false,
    modified = '',
    name = '',
    artistname = '',
    parentfolderid = 0,
    thumb = false,
  } = metadata;

  const itemData = {
    comments: comments,
    created: created,
    folderid: folderid,
    fileid: fileid,
    icon: icon,
    hash: hash,
    id: id,
    isfolder: isfolder,
    ismine: ismine,
    isshared: isshared,
    isalbum: isalbum,
    isplaylist: isplaylist,
    modified: modified,
    name: name,
    artistname: artistname,
    parentfolderid: parentfolderid,
    thumb: thumb,
    items: {}
  };

  if (metadata.hasOwnProperty('encrypted')) {
    itemData.encrypted = metadata.encrypted;
  }
  if (metadata.hasOwnProperty('time')) {
    itemData.time = metadata.time;
  }
  if (metadata.hasOwnProperty('tm_key')) {
    itemData.tm_key = metadata.tm_key;
  }

  if (contents.length) {
    contents.forEach(element => {
      itemData.items[element.id] = element;
    });
  }

  return itemData;
};

const addItem = (state, metadata) => {
  let data = { ...metadata };
  const { itemData } = state;
  const { parentfolderid = 0, isfolder = false, contents = [] } = data;

  if (itemData.id === "d" + parentfolderid) {
    itemData.items[metadata.id] = data;
  } else if (itemData.items && itemData.items["d" + parentfolderid]) {
    if (!itemData.items["d" + parentfolderid].contents) {
      itemData.items["d" + parentfolderid].contents = [];
    }
    itemData.items["d" + parentfolderid].contents.push(data.id);
  }

  return { ...itemData };
};

const deleteItem = (state, metadata) => {
  const { itemData } = state;
  const { parentfolderid, id } = metadata;

  if (itemData.items && itemData.items[id]) {
    delete itemData.items[id];
  }

  if (itemData.items && itemData.items["d" + parentfolderid] && itemData.items["d" + parentfolderid].contents) {
    itemData.items["d" + parentfolderid].contents = itemData.items["d" + parentfolderid].contents.filter(elementId => elementId !== id);
  }

  return { ...itemData };
};

const modifyItem = (state, metadata) => {
  let data = { ...metadata };
  const { itemData } = state;
  const { id, parentfolderid } = metadata;

  if (metadata.isplaylist) {
    if (itemData.items[id] && itemData.items[id].id === id) {
      itemData.items[id].name = metadata.name;
    } else if (itemData.id === id) {
      itemData.name = metadata.name;
    }

    return { ...itemData };
  } else if (metadata.isfilerequest) {
    if (itemData.items[id] && itemData.items[id].id === id) {
      itemData.items[id].comment = metadata.comment;
      if (metadata.maxspace) {
        itemData.items[id].maxspace = metadata.maxspace;
      } else {
        delete itemData.items[id].maxspace;
      }
      if (metadata.expires) {
        itemData.items[id].expires = metadata.expires;
      } else {
        delete itemData.items[id].expires;
      }
    }

    return { ...itemData };
  } else if (metadata.issharedlink) {
    if (itemData.items[id] && itemData.items[id].id === id) {
      itemData.items[id] = metadata;
    }

    return { ...itemData };
  }

  if (itemData.folderid !== parentfolderid) {
    if (itemData.items && itemData.items[id]) {
      if (itemData.items[id].id === id && itemData.items[id].issharedbyme) {
        itemData.items[id].name = metadata.name;
      } else {
        // Content moved
        delete itemData.items[id];
      }
    };
  } else {
    if (itemData.items && itemData.items[id]) {
      if (metadata.category === HFN.CATEGORY.IMAGE && metadata.thumb) {
        // thumb-1--40x40-15747698833754946000-0
        const cacheid = itemData.items[id].hash + '-' + (itemData.items[id].revisionid || 0);
        HFN.cache.expireMatch('thumb-0--[^-]+-' + cacheid);
        HFN.cache.expireMatch('thumb-1--[^-]+-' + cacheid);
      }
      itemData.items[id] = metadata;
    } else if (itemData.id === id) {
      itemData.icon = metadata.icon;
      itemData.ismine = metadata.ismine;
      itemData.isshared = metadata.isshared;
      itemData.modified = metadata.modified;
      itemData.name = metadata.name;
      itemData.parentfolderid = metadata.parentfolderid;
      itemData.thumb = metadata.thumb;
    }
  }

  return { ...itemData };
};

const setContentKeys = data => {
  return [...data];
};

const setDefaultBranding = data => {
  return data;
};

const loadBreadcrumb = data => {
  const breadcrumb = {};

  data.forEach(element => {
    breadcrumb[element.id] = element;
  });

  return breadcrumb;
};

const modifyBreadcrumb = (state, metadata) => {
  const { breadcrumb } = state;
  const { id } = metadata;

  if (breadcrumb[id]) {
    breadcrumb[id] = metadata;
  }

  return { ...breadcrumb };
}

export const setActiveExploreCard = (index) => ({
  type: SET_ACTIVE_EXPLORE_CARD,
  payload: index,
});

export const incrementExploreCard = () => ({
  type: INCREMENT_EXPLORE_CARD,
});

export const decrementExploreCard = () => ({
  type: DECREMENT_EXPLORE_CARD,
});

export const setGridHeaderHeight = () => ({
  type: SET_GRID_HEADER_HEIGHT,
});

export const contentReducer = (
  state: ContentState = initialState,
  action: Action
): ContentsState => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_CONTENT:
      return { ...state, loaded: payload };
    case SET_CONTENT_ITEMS:
      return { ...state, itemData: setContentItems(payload) };
    case ADD_CONTENT_ITEM:
      return { ...state, itemData: addItem(state, payload) };
    case DELETE_CONTENT_ITEM:
      return { ...state, itemData: deleteItem(state, payload) };
    case MODIFY_CONTENT_ITEM:
      return { ...state, itemData: modifyItem(state, payload), breadcrumb: modifyBreadcrumb(state, payload) };
    case DELETE_CONTENT_ITEMS:
      return { ...state, itemData: {}, itemKeys: [] };
    case SET_CONTENT_KEYS:
      return { ...state, itemKeys: setContentKeys(payload) };
    case SET_DEFAULT_BRANDING:
      return { ...state, defaultBranding: setDefaultBranding(payload) };
    case LOAD_BREADCRUMB:
      return { ...state, breadcrumb: loadBreadcrumb(payload) };
    case DELETE_BREADCRUMB:
      return { ...state, breadcrumb: [] };
    case SET_LEFT_MENU_CONTENT:
      return { ...state, leftMenuContent: payload };
    case SET_ACTIVE_EXPLORE_CARD:
      return { ...state, activeExploreCardIndex: payload };
    case INCREMENT_EXPLORE_CARD:
      return { ...state, activeExploreCardIndex: state.activeExploreCardIndex + 1 };
    case DECREMENT_EXPLORE_CARD:
      return { ...state, activeExploreCardIndex: state.activeExploreCardIndex - 1 };
    case SET_PAGE_INFO:
      return { ...state, pageInfo: payload };
    case SET_GRID_HEADER_HEIGHT:
      return { ...state, gridHeaderHeight: payload };
    case SET_ITEMS_INFO_PANEL_DATA:
      return { ...state, itemsInfoPanelData: payload };
    default:
      return state;
  }
};

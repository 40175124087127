// @flow
// WARNING: Do not use HFN in this component

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Componentify from "react-componentify";
import styled from "styled-components";
import shallowCompare from "react-addons-shallow-compare";

// import shallowEqual from "fbjs/lib/shallowEqual";
// import OpenAccountButton from "../components/OpenAccountButton";
// import RegistrationButton from "../components/RegistrationButton";
import Modal, { OvalModalWrap } from "../../components/Modal"; // DONE
import register from "../../api/register"; // DONE
import { errorKeys } from "../../lib/errors"; // DONE
import { __ } from "../../lib/translate"; // DONE
import { validateEmail, getSearch, getHash, setcookie, rcookie, getLocationById } from "../../lib/utils"; // DONE

import { SHAREASALE_REFS, LOGIN_THEMES, DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import { boldConverter, linkConverter, linkConverterV2 } from "../../lib/componentifyConverters"; // DONE
import { setGlobalLocationById, getApiServerCall } from "@pcloud/web-utilities/dist/api/utils.api"; // DONE
import apiConfig from "../../api/config"; // DONE
import DataCenterDropdown from "./DataCenterDropdown"; // DONE
import * as FormStyle from "../../components/FormSharedComponents"; // DONE
import * as Style from "../SharedInvitationComponents"; // DONE
import type { LocationType } from "../../types/dataregion"; // DONE

import InputWrapper from "./MultiplatformComponents/Elements/InputWrapper"; // DONE
import Input from "./MultiplatformComponents/Elements/Input"; // DONE
import SigninOptionsWrapper from "./MultiplatformComponents/Elements/SigninOptionsWrapper"; // DONE
import CheckBox from "./MultiplatformComponents/Elements/CheckBox"; // DONE
import Button from "./MultiplatformComponents/Elements/Button"; // DONE
import ButtonWrapper from "./MultiplatformComponents/Elements/ButtonWrapper"; // DONE
import Notification, { notify } from "./MultiplatformComponents/Notification"; // DONE
import ErrorIconAndroid from "../../../root/img/svg/error-android.svg"; // DONE
import ErrorIconiOS from "../../../root/img/svg/error-ios.svg"; // DONE
import ErrorBox from "./MultiplatformComponents/Elements/ErrorBox"; // DONE
import ErrorMessage from "./MultiplatformComponents/Elements/ErrorMessage"; // DONE
import Form from "./MultiplatformComponents/Elements/Form"; // DONE

require("../../styles/forms.less"); // DONE
require("../../styles/registration.less"); // DONE

type Props = {
  email?: string,
  password?: string,
  terms?: boolean,
  isLogged: boolean,
  language: string,
  subTitle: string,
  showTitle: boolean,
  promoCode: string,
  showPromoCode?: boolean,
  title: string,
  theme: any,
  userRegionId: number,
  locations?: Array<LocationType>,
  beforeRegisterMessage?: string,
  passpcloud: boolean,
  usegrecaptcha: boolean,
  onRegister: () => void,
  onSuccess: ({ action: string, userinfo: string }) => void,
  onError: () => void
};

type State = {
  email: string,
  password: string,
  terms: boolean,
  isRegistering: boolean,
  isRegistered: boolean,
  showPassword: boolean,
  error: string,
  shouldRenderBeforeRegister: boolean
};

class Registration extends Component<Props, State> {
  static defaultProps = {
    email: "",
    password: "",
    language: "en",
    subTitle: "",
    showTitle: true,
    title: "",
    beforeRegisterMessage: "",
    theme: {},
    terms: false,
    beforeRegister: null,
    onRegister: () => {},
    onSuccess: () => {},
    onError: () => {},
    locations: [],
    promoCode: "",
    passpcloud: false
  };

  constructor(props: Props) {
    super(props);

    (this: any).onEnter = this.onEnter.bind(this);
    (this: any).handlePasswordIconClick = this.handlePasswordIconClick.bind(this);
    (this: any).onTermsClick = this.onTermsClick.bind(this);
    (this: any).onCreateAccountClick = this.onCreateAccountClick.bind(this);
    (this: any).createAccount = this.createAccount.bind(this);
    (this: any).onYesClick = this.onYesClick.bind(this);
    (this: any).onCloseBeforeRegisterClick = this.onCloseBeforeRegisterClick.bind(this);
    (this: any).renderMessage = (this: any).renderMessage.bind(this);
    (this: any).showMessage = (this: any).showMessage.bind(this);

    this.state = {
      email: props.email || "",
      password: props.password || "",
      terms: props.terms || false,
      isRegistering: false,
      isRegistered: false,
      showPassword: false,
      error: "",
      shouldRenderBeforeRegister: false
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return shallowCompare(this, nextProps, nextState);
  }

  onEnter(e: any) {
    e.stopPropagation();
    if (e.keyCode === 13) {
      this.onCreateAccountClick(e);
    }
  }

  setInput(name: string, value: string) {
    this.setState({ [name]: value });
  }

  handlePasswordIconClick() {
    this.setState(prevState => {
      return { showPassword: !prevState.showPassword };
    });
  }

  onTermsClick() {
    this.setState(({ terms: prevTerms }) => ({
      terms: !prevTerms
    }));
  }

  onCreateAccountClick(e: any) {
    e.preventDefault();
    const { isRegistering, isRegistered, email, password } = this.state;

    if (isRegistering || isRegistered || !email || !password) {
      return;
    }

    const { beforeRegisterMessage, userRegionId, usegrecaptcha } = this.props;
    const isSameCurrentLocation = parseInt(apiConfig.locationid) === parseInt(userRegionId);

    if (beforeRegisterMessage && !isSameCurrentLocation) {
      this.createAccountStatus(() => this.setState({ shouldRenderBeforeRegister: true }));
    } else {
      const self = this;
      this.createAccountStatus(() => {
        if (typeof grecaptcha !== "undefined" && usegrecaptcha) {
          console.log("grecaptcha init");
          grecaptcha.ready(function() {
            grecaptcha.execute('6Ld3iconAAAAAMeIhfk_3jTdIPSNX_OcSY6QlvZR', {action: 'submit'}).then((token) => {
              console.log("grecaptcha ready");
              self.createAccount({ gresponsetoken: token })
            });
          });
        } else {
          this.createAccount({})
        }        
      });
    }
  }

  onYesClick() {
    this.setState({ shouldRenderBeforeRegister: false });
    this.createAccountStatus(this.createAccount);
  }

  onCloseBeforeRegisterClick() {
    this.setState({ shouldRenderBeforeRegister: false });
  }

  createAccountStatus(cb: () => void) {
    const { email, password, terms, isRegistering } = this.state;
    let error = "";

    if (isRegistering) {
      error = __("registration_in_progress", "Registration in progress");
    }

    if (!terms) {
      error = __("must_agree_terms", "You must agree with Terms of Service.");
    }
    if (password.length < 6) {
      error = __("password_too_short", "Password is too short.");
    }
    if (!password) {
      error = __("password_required", "Password is requried.");
    }
    if (!validateEmail(email)) {
      error = __("invalid_email", "Invalid Email");
    }

    if (error) {
      this.showMessage(error, "error");
    } else {
      cb();
    }
  }

  createAccount({ gresponsetoken } = {}) {
    const { passpcloud, language, onSuccess, userRegionId, promoCode, onError, os } = this.props;
    const { email, password } = this.state;
    const urlref = rcookie("ref") || getHash("ref") || getSearch("ref");
    const apiServer = getLocationById(userRegionId) && getLocationById(userRegionId).api;

    let opts = {
      username: email,
      password: password,
      language: language,
      passpcloud: passpcloud,
      apiServer: apiServer,
      gresponsetoken: gresponsetoken,
      os: os
    };

    if (promoCode) {
      opts.promocode = promoCode;
    }

    this.setState({ isRegistering: true, error: "" });
    register({
      ...opts,
      onDone: userInfo => {
        const recaptchaBadge = document.querySelector(".grecaptcha-badge");

        if (SHAREASALE_REFS.some(ref => ref == urlref)) {
          console.log("SET UP A 'SHARE A SALE COOKIE'");
          setcookie("shareasale", userInfo.userid);
        }
        setcookie("email", email);
        setGlobalLocationById(userRegionId);
        getApiServerCall();
        this.setState({ isRegistered: true, isRegistering: false });
        onSuccess({
          action: "register",
          userinfo: userInfo,
          locationid: userRegionId
        });

        if (recaptchaBadge) {
          recaptchaBadge.style.visibility = "hidden";
        }
      },
      onError: ret => {
        const errorMessage = errorKeys[ret.result] || ret.error;
        this.setState({ isRegistering: false });
        console.log("onError REgistration my", ret);
        this.showMessage(__(errorMessage), "error");
        onError(ret);
      },
      onTimeout: () => {
        this.setState({ isRegistering: false });
        this.showMessage(__("error_connection_timed_out"), "error");
      }
    });
  }

  showMessage(message, type) {
    const { os } = this.props;

    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && type === "error") {
      this.setState({ error: message });
    } else {
      notify(message, type, { os });
    }
  }

  renderMessage() {
    const { error } = this.state;
    const { os } = this.props;
    const errorMessageIcons = {
      [DEVICE_OS.Web]: <ErrorIconAndroid width="28" height="24" />,
      [DEVICE_OS.iOS]: <ErrorIconiOS width="26" height="24" />,
      [DEVICE_OS.Android]: <ErrorIconAndroid width="28" height="24" />
    };
    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && error) {
      return (
        <ErrorBox os={os}>
          <div className="icon-wrapper">{errorMessageIcons[os]}</div>
          <ErrorMessage className="login-error-message" os={os}>
            {error}
          </ErrorMessage>
        </ErrorBox>
      );
    }

    return <Notification os={os} />;
  }

  renderBeforeRegisterModal() {
    const { beforeRegisterMessage } = this.props;
    const { shouldRenderBeforeRegister } = this.state;

    return (
      <Modal opened={shouldRenderBeforeRegister} onClose={this.onCloseBeforeRegisterClick}>
        <OvalModalWrap>
          <Style.Title className="modal before-continue">{__("before_you_continue", "Before you continue")}</Style.Title>
          <Style.WarningMessage className="modal">
            <Style.ContainerCol>
              <Style.WarnIcon className="modal" />
              <Style.ComponentifyWrapper className="modal">
                <Componentify text={beforeRegisterMessage} converters={[boldConverter]} />
              </Style.ComponentifyWrapper>
            </Style.ContainerCol>
          </Style.WarningMessage>

          <Style.ContainerCol>
            <Style.ButtonContainerInline className="modal">
              <Style.ButtonBlue onClick={this.onYesClick}>{__("Yes")}</Style.ButtonBlue>
              <Style.ButtonGrey onClick={this.onCloseBeforeRegisterClick}>{__("Cancel")}</Style.ButtonGrey>
            </Style.ButtonContainerInline>
          </Style.ContainerCol>
          <Style.CloseButton onClick={this.onCloseBeforeRegisterClick} />
        </OvalModalWrap>
      </Modal>
    );
  }

  render() {
    const { os, isLogged, theme, locations } = this.props;
    const { email, password, showPassword, terms, isRegistered, isRegistering, error } = this.state;
    const themeStyle = theme && LOGIN_THEMES[theme] ? LOGIN_THEMES[theme] : {};
    const PassIcon = showPassword ? FormStyle.ShowPasswordIcon : FormStyle.HidePasswordIcon;
    const isMobileOrMacOSX = os === DEVICE_OS.iOS || os === DEVICE_OS.Android || os === DEVICE_OS.MacOSX;
    const pcloudTermsKey = isMobileOrMacOSX
      ? __(
          "i_accept_pcloud_terms_mobile",
          "I Accept pCloud's https://www.pcloud.com/policy/terms_conditions/[Terms & Conditions], https://www.pcloud.com/policy/privacy_policy/[Privacy Policy] and https://www.pcloud.com/policy/intellectual_privacy/[Intellectual Property Policy]'"
        )
      : __(
          "i_accept_pcloud_terms",
          "I Accept pCloud's https://www.pcloud.com/terms_and_conditions.html[Terms & Conditions], https://www.pcloud.com/privacy_policy.html[Privacy Policy] and https://www.pcloud.com/int_pr_policy.html[Intellectual Property Policy]"
        );

    if (isLogged) {
      return <div />;
    }

    return (
      <Form os={os} onSubmit={this.onCreateAccountClick}>
        {this.renderMessage()}
        <div>
          <InputWrapper os={os}>
            <Input
              os={os}
              name="email"
              type="email"
              autoComplete="email"
              autocapitalize="off"
              placeholder={__("form_email", "Email")}
              value={email}
              onChange={e => this.setInput("email", e.target.value)}
              onKeyDown={this.onEnter}
              className="register-input-email"
            />
          </InputWrapper>
          <InputWrapper os={os} className="register-input-password-wrapper">
            <Input
              os={os}
              name="password"
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
              placeholder={__("form_password", "Password")}
              value={password}
              onChange={e => this.setInput("password", e.target.value)}
              onKeyDown={this.onEnter}
              className="register-input-password"
            />
            <PassIcon onClick={this.handlePasswordIconClick} className="register-input-password-icon" />
          </InputWrapper>
          <InputWrapper os={os}>
            <DataCenterDropdown os={os} locations={locations} themeStyle={themeStyle} theme={theme} />
          </InputWrapper>
        </div>

        <SigninOptionsWrapper os={os}>
          <CheckBox
            os={os}
            name="terms"
            size="small"
            checked={terms}
            onChange={e => this.setState({ terms: e.target.checked })}
            className="register-terms-checkbox"
          >
            <FormStyle.Terms className="regsitration-terms" onClick={this.onTermsClick} style={themeStyle.checkboxLabel}>
              <Componentify
                className="terms-text"
                text={__(pcloudTermsKey)}
                converters={[boldConverter, linkConverter, linkConverterV2]}
              />
            </FormStyle.Terms>
          </CheckBox>
        </SigninOptionsWrapper>
        <ButtonWrapper os={os}>
          <Button
            os={os}
            type="submit"
            // active={!isRegistering && !isRegistered}
            disabled={isRegistering || isRegistered || !email || !password}
            loading={isRegistering}
            onClick={this.onCreateAccountClick}
            className="register-button-submit"
          >
            {__("create_account", "Create Account")}
          </Button>
        </ButtonWrapper>
        {this.renderBeforeRegisterModal()}
      </Form>
    );
  }
}

export default connect(
  ({ user: { userRegionId } = {} }) => {
    return { userRegionId };
  },
  dispatch => bindActionCreators({}, dispatch)
)(Registration);

export const RegistrationWrap = ({ children }) => <div className="registration-wrap">{children}</div>;

const Wrapper = styled.div`
  position: relative;

  .butt {
    border-radius: 7px;
  }
`;

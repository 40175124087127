import uniqueNum from "./uniqueNum";

const popOver = {
    defaultOpts: {
        el: "",

        pos: "left",
        align: "top",
        valign: "center",

        trigger: "click",
        to: null,

        objClass: "",
        genClass: "popoverObj",
        evNamespace: ".po",
        spacing: 11,
        centered: true,
        single: true,
        mobileOpen: true,
        elOpenClass: "popover-opened",
        appendTo: "sibling",
        onOpen() { },
        onClose() { }
    },
    detach(opts) {
        this.closeAll();

        this.close(opts.el);
        opts.el.removeData();
        opts.el.el.removeData();
    },
    attach(opts) {
        opts = $.extend({}, this.defaultOpts, opts);

        if (typeof opts.el === "string" && ["#", "."].indexOf(opts.el.charAt(0)) != -1) {
            opts.el = $(opts.el);
        }

        // is updating
        if (opts.el.data("popover")) {
            this.close(opts.el);
            opts.el.removeData();
        }

        if (opts.obj && !opts.el.data("popover")) {
            opts.obj = opts.el.data("popover", opts.obj);
            delete opts.obj;
        }

        opts.objClass = uniqueNum.get("popoverObj");

        const self = this;
        const { el } = opts;
        delete opts.el;

        if (!el.data("popover")) {
            return;
        }

        el.data("opts", opts)
            .off(opts.evNamespace)
            .on(opts.trigger + opts.evNamespace, function (e) {
                // e.stopPropagation();

                if (self.isOpen(opts) && opts.trigger == "click") {
                    self.close($(this));
                } else {
                    self.open($(this));
                }
            });

        return function () {
            popOver.closeAll();

            el.removeData();
            el.off(opts.evNamespace);
        };
    },
    update(el, popover, updateUI) {
        el.data("popover", popover);
        updateUI = updateUI || false;
        if (updateUI) {
            el.data("obj")
                .off(".po")
                .detach();
            popOver.draw(el);
        }
    },
    setupClose(el) {
        const self = this;
        const obj = el.data("obj");
        const opts = el.data("opts");

        if (opts.trigger == "click") {
            $(document).on(`click${opts.evNamespace}`, (e) => {
                if (!$(e.target).hasClass(opts.objClass) && !$(e.target).closest(`.${opts.objClass}`).length) {
                    // e.stopImmediatePropagation();
                    self.close(el);
                }
            });
            $(document).on(`click${opts.evNamespace}`, '.popover-close', () => {
                self.close(el);
            });
        } else {
            el.mouseleave(this.set.bind(this, el));
            obj.on("mouseleave.po", this.set.bind(this, el));
            obj.on("mouseenter.po", this.stop.bind(this, el));
            el.mouseenter(this.stop.bind(this, el));
        }

        $(window).on(`resize${opts.evNamespace}`, (e) => {
            self.position(el, true);
        });

        $(window).on(`keyup${opts.evNamespace}`, (e) => {
            self.closeAll();
        });
    },

    draw(el) {
        const opts = el.data("opts");
        const obj = el.data("popover");
        let parent;

        el.data("obj", obj);

        if (opts.appendTo == "sibling") {
            parent = el.parent();
        } else {
            parent = $(opts.appendTo);
        }

        obj.appendTo(parent)
            .show()
            .addClass(opts.objClass)
            .addClass("popover-base")
            .addClass(opts.genClass);

        obj.addClass(opts.pos)
            .addClass(`a${opts.align}`)
            .addClass(`v${opts.valign}`)
            .css("z-index", "1002")
            .css("position", opts.cssPosition)
            .css("display", "table");

        if (opts.mobileOpen) {
            obj.css({
                zIndex: "110",
                top: "auto"
            });
        } else {
            obj.css({ opacity: 1 });
        }
        // .show();

        this.position(el);
    },

    open(el) {
        const opts = el.data("opts");
        const obj = el.data("popover");

        if (opts.single && $(".popover-opened").length) {
            this.close($(".popover-opened"));
        }

        this.draw(el);
        el.addClass(opts.elOpenClass);

        setTimeout(() => {
            this.setupClose(el);
        }, 0);

        opts.onOpen();
    },
    position(el, disableTransition = false) {
        const obj = el.data("popover");
        const opts = el.data("opts");

        if (opts.mobileOpen) {
            obj.css({
                bottom: 0,
                left: "100vw",
                position: opts.position
            }).animate(
                {
                    left: 0
                },
                {
                    duration: disableTransition ? 0 : 500
                },
                "easeout"
            );

            obj.off("swiperight").on("swiperight", () => {
                this.close(el);
            });
            return;
        }

        let x = el.offset().left;
        let y = el.offset().top - $(window).scrollTop();

        if (opts.position === "absolute" && 0) {
            y += $(window).scrollTop();
        }

        // position
        if (opts.pos == "top") y -= obj.outerHeight() + opts.spacing;
        else if (opts.pos == "bottom") y += el.outerHeight() + opts.spacing;
        else if (opts.pos == "left") x -= obj.outerWidth() + opts.spacing;
        else if (opts.pos == "right") x += el.outerWidth() + opts.spacing;

        // align
        if (opts.align == "left") 1;
        else if (opts.align == "right") x -= obj.outerWidth() - el.outerWidth();
        else if (opts.align == "top") 1;
        else if (opts.align == "bottom") y -= obj.outerWidth() - el.outerHeight();
        else if (opts.align == "center") x -= obj.outerWidth() / 2 - el.outerWidth() / 2;

        // valign
        if (opts.valign == "top") 1;
        else if (opts.valign == "bottom") y -= obj.outerHeight() - el.outerHeight();
        else if (opts.valign == "center") y -= obj.outerHeight() / 2 - el.outerHeight() / 2;

        // flyin
        let top = parseInt(y), left = parseInt(x);
        if (opts.flyin === "top") {
            top -= 20;
        } else if (opts.flyin === "bottom") {
            top += 20;
        } else if (opts.flyin === "left") {
            left -= 20
        } else if (opts.flyin === "right") {
            left += 20
        }

        obj.css({
            top: top,
            left: left,
            position: opts.position
        }).animate(
            {
                top: y,
                left: x,
                opacity: 1,
            },
            {
                duration: disableTransition ? 0 : 150
            }
        );
    },
    close(el) {
        const opts = el.data("opts");
        const obj = el.data("obj");
        let x = el.offset().left;
        let y = el.offset().top - $(window).scrollTop();

        if (opts === undefined) {
            return;
        }

        $(window).off(opts.evNamespace);
        $(document).off(opts.evNamespace);
        document.removeEventListener("touchstart", this.handleTouch);
        // $(opts.el).off(opts.evNamespace)

        // flyin
        let top = obj ? parseInt(obj.css("top")) : 0;
        let left = obj ? parseInt(obj.css("left")) : 0;

        if (opts.flyin === "top") {
            top -= 20;
        } else if (opts.flyin === "bottom") {
            top += 20;
        } else if (opts.flyin === "left") {
            left -= 20;
        } else if (opts.flyin === "right") {
            left += 20;
        }

        if (obj) {
            if (opts.mobileOpen) {
                obj.animate(
                    {
                        left: "100vw"
                    },
                    {
                        duration: 500,
                        complete: () => {
                            el.removeClass(opts.elOpenClass);
                            obj.off(".po").detach();
                        }
                    },
                    "easeout"
                );
            } else {
                obj.animate(
                    {
                        top,
                        left,
                        opacity: 0
                    },
                    {
                        duration: 150,
                        complete: () => {
                            el.removeClass(opts.elOpenClass);
                            obj.off(".po").detach();
                        }
                    }
                );
            }
        }

        opts.onClose();
    },
    isOpen(opts) {
        return $(`.${opts.objClass}`).length;
    },

    // timeouts
    set(el) {
        const self = this;
        const opts = el.data("opts");

        if (opts.to) {
            clearTimeout(opts.to);
        }

        opts.to = setTimeout(() => {
            self.close(el);
        }, 350);
    },
    stop(el) {
        const opts = el.data("opts");
        clearTimeout(opts.to);
    },

    closeAll() {
        const self = this;
        $(".popover-opened").each((n, item) => {
            self.close($(item));
        });
    }
};

export default popOver;

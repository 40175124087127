// @flow
// SHARED FOLDERS CONSTANTS
export const LOAD_SHARED = "LOAD_SHARED";
export const UNLOAD_SHARED = "UNLOAD_SHARED";
export const LOAD_SHARED_FOLDERS = "LOAD_SHARED_FOLDERS";
export const ACCEPT_SHARE_TO_FOLDER_IN = "ACCEPT_SHARE_TO_FOLDER_IN";
export const ACCEPT_SHARE_TO_FOLDER_OUT = "ACCEPT_SHARE_TO_FOLDER_OUT";
export const LOAD_FOLDER_SHARES = "LOAD_FOLDER_SHARES";
export const CLEAR_FOLDER_SHARES = "CLEAR_FOLDER_SHARES";
export const GET_SHARED_ITEMS = "GET_SHARED_ITEMS";
export const ADD_SHARE_TO_FOLDER = "ADD_SHARE_TO_FOLDER";
export const DELETE_SHARE_TO_FOLDER = "DELETE_SHARE_TO_FOLDER";
export const UPDATE_SHARE_TO_FOLDER = "UPDATE_SHARE_TO_FOLDER";
export const ACCEPTED_SHARE_TO_FOLDER = "ACCEPTED_SHARE_TO_FOLDER";
export const GET_SHARE_MESSAGE = "GET_SHARE_MESSAGE";
export const GET_SHARE_PERMISSION = "GET_SHARE_PERMISSION";
export const GET_SHARE_FOLDER_NAME = "GET_SHARE_FOLDER_NAME";

// @flow

import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

export const Container = styled.div`
  max-width: ${props => (props.formWidth ? props.formWidth : "450px")};
`;

export const InputPassWrapper = styled.div`
  position: relative;
`;

export const Terms = styled.div`
  display: inline-block;
  font-size: 13px;
  line-height: 1.4;
  color: #000;
  text-align: left;
  cursor: pointer;
  margin-left: 10px !important;
  margin-top: -2px;
   a {
    color: #26b8c7;
    text-decoration: none !important;
   }
`;

export const ShowPasswordIcon = styled.div`
  display: block;
  width: 18px;
  height: 18px;
  cursor: pointer;

  position: absolute;
  margin-top: -9px;
  top: 50%;
  right: 11px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../root/img/data-location/show-pass.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/data-location/show-pass@2x.png")});
  }
`;

export const HidePasswordIcon = styled(ShowPasswordIcon)`
  background-size: contain;
  background-image: url(${require("../../root/img/data-location/hide-pass.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/data-location/hide-pass@2x.png")});
  }
`;

export const TermsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`;

export const Title = styled.div`
  color: #000;
  font-size: 20px;
  text-align: center;
  display: block;
  font-weight: bold;
  margin-bottom: ${props => (props.subTitle ? "5px" : "0")};

  &.login-title, &.register-title, &.invite-form {
    text-align: left;
  }

  &.webview {
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 16px;
    margin: 20px 0 20px 0;

    &.webview {
      margin: 20px 0 0 0;
    }
  }
`;

export const Subtitle = styled.div`
  display: block;
  color: #999;
  font-size: 13px;
  text-align: center;
  font-weight: normal;
  margin: 10px 0 5px 0;

  &.external {
    margin-bottom: 35px;
  }
`;

export const ErrorMessage = styled.div`
  color: #ff4d4d;
  text-align: center;
  line-height: 15px;
  padding: 5px 0;
  min-width: initial;
  min-height: 15px;
  font-size:13px;
`;

export const Or = styled.div`
  text-align: center;
  margin: 10px 0px;
  color: #999;
  font-size: 13px;
`;

export const Link = styled.div`
  max-width: 640px;
  margin: 25px auto;
  font-size: 13px;
  line-height: 1.23;
  text-decoration: underline;
  text-align: center;
  color: #17bed0;
  cursor: pointer;
`;

export const FooterText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: #000000;
  font-weight: 500;
  margin-top: 30px;
`;

export const FooterButton = styled.div`
  display: inline-block;
  color: #17bed0;
  text-decoration: none;
  margin-left: 5px;
  cursor: pointer;
`;

export const SigninDiffAccount = styled.div`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #17bed0;
  margin-top: 35px;
  cursor: pointer;
`;

const Pulsate = keyframes`
  0% {transform: scale(0.1, 0.1); opacity: 0;}
  50% {opacity: 1;}
  100% {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.2, 1.2);
    transform: translateZ(0);
    transform: scale(1.0, 1.0);
    opacity: 0;
    }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background-color: #fff;
`;

export const Loader = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  border: 4px solid #20bed6;
  border-radius: 30px;
  background-color: #fff;
  animation: ${Pulsate} 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
`;

export const BlockElement = styled.div`
  text-align: center;
`;
